@tailwind base;
@tailwind components;
@tailwind utilities;

/**
* 🚀 Print Media Mixins
**/
@mixin printMedia($paperSize) {
  @if $paperSize == 'A3' {
    //A3 Media Styles
    @media print {
      @page {
        size: A3 portrait;
      }
      /* Styles for A4 size */
      @media print and (size: A3) {
        @content;
      }
    }
  } @else if $paperSize == 'A4' {
    @media print {
      @page {
        size: 210mm 297mm; /* A4 size */
        margin: 0;
      }
      body {
        width: 210mm;
        height: 297mm;
      }

      /* styles for A4 paper size */
      @content;
    }
  } @else if $paperSize == 'A5' {
    @media print {
      @page {
        size: 148mm 210mm; /* A5 size */
        margin: 0;
      }
      body {
        width: 148mm;
        height: 210mm;
      }
      /* styles for A5 paper size */
      @content;
    }
  }
}

/**
* ===============================
* 🚀 General CSS
* ===============================
**/
* {
  @apply font-jost;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-jost text-cblack-default;
}
p,
a {
  @apply text-cblack-default;
}
.btn {
  @apply px-4 py-2 rounded font-bold;
  &.btn-primary {
    @apply bg-cblue-500 text-white;
  }
  &.btn-danger {
    @apply bg-red-400 text-white;
  }
  &.btn-rounded {
    @apply w-10 h-10 rounded-full;
  }
  &.btn-info {
    @apply w-12 h-12 bg-slate-50 text-slate-900;
  }
  .btn-icon {
    @apply text-2xl;
  }
  &.btn-outlined {
    @apply border-2 border;
  }
  &.btn-next {
    @apply flex flex-row items-center gap-2 bg-cblue-500 text-white;
  }
}
.bg {
}
.bg-primary {
  @apply bg-cblue-500 text-white;
}

// Square Avatar

//Circular Avatar
.avatar {
  @apply relative w-fit;
  .img {
    @apply h-40 w-40 bg-cgold-600 border-4 border-cgold-600 rounded-full object-contain;
  }
  .flag {
    @apply w-12 h-12 absolute z-20 bottom-0 right-0;
  }
  .loading {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background: #e2e2e2;
    color: transparent;
    overflow: hidden;

    &::before {
      content: '';
      position: absolute;
      left: 0%;
      top: 0;
      height: 100%;
      width: 50px;
      background: linear-gradient(to right, #e2e2e2 25%, #d5d5d5 50%, #e2e2e2 100%);
      animation-name: gradient-animation;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      filter: blur(5px);
    }
    @apply h-40 w-40 rounded-full;

    @keyframes gradient-animation {
      from {
        left: 0%;
      }
      to {
        left: 100%;
      }
    }
  }
}

// Colors

.black_19 {
  color: #191919;
}

/**
* ===============================
* 🚀 Components CSS
* ===============================
**/
//Video Info Popup
.video-info-popup {
  .btn-popup-toggle {
    @apply bg-gray-100/70 backdrop-blur #{!important};
  }
  .popup {
    @apply absolute top-0 left-0 right-0 bottom-0 bg-gray-900/80 p-6;
    .btn-times {
      @apply w-12 h-12 bg-slate-50 text-slate-900;
    }
    .content-container {
      @apply h-full flex flex-col justify-center items-center;
      .content {
        .heading {
          @apply text-center text-white text-lg font-bold mb-2;
        }
        .description {
          @apply text-base text-white;
        }
      }
    }
  }
}
//Video Controls
.video-controls-container {
  @apply absolute inset-0;
  .controls {
    @apply absolute bottom-0 left-0 right-0 p-3 flex w-full justify-center items-center;
    .cta-container {
      @apply w-full h-full flex items-end;
    }
    .flex-container {
      @apply w-full flex justify-center items-center pb-10;
    }
    .btn-record {
      @apply bg-red-500 border-8 border-white w-20 h-20 mx-8;
    }
    .btn-stop {
      @apply bg-red-500 border-8 border-white w-20 h-20 mx-8;
      .icon {
        @apply text-3xl text-white mx-auto;
      }
    }
    .btn-cancel {
      @apply bg-zinc-900/70 text-2xl text-white;
    }
  }
}

//Popup
.popup {
  @apply absolute inset-0 bg-gray-900/80 p-6;
  .btn-times {
    @apply absolute w-12 h-12 bg-slate-50 text-slate-900;
  }
}
.sm-popup {
  @apply absolute inset-0;
  .content-container {
    @apply h-full w-full flex  px-6;
    .content {
      @apply bg-white h-fit m-auto px-4 py-6 shadow-md text-center;
      .btn-times {
        @apply bg-cblue-500 flex items-center text-white h-11 w-11 text-center text-xl rounded-full;
      }
      .heading {
        @apply text-xl font-bold;
      }
    }
  }
}
.video-permissions {
  @apply text-center flex flex-col h-full align-middle justify-center h-screen p-10;
  .icon-box {
    .icon {
      @apply text-6xl mx-auto;
    }
  }
  .heading {
    @apply text-2xl font-semibold mb-4;
  }
  .content {
    @apply text-base mb-4;
  }
  .p {
    @apply text-base font-bold mb-4;
  }
}
.redo-screen {
  @apply text-white flex flex-col justify-between items-center h-full pt-40 text-center px-10;
  .action {
    @apply my-6;
    .heading {
      @apply text-xl font-semibold text-white;
    }
    .btn-action {
      @apply bg-slate-500 text-white my-6;
    }
    .text {
      @apply text-base text-white underline;
    }
  }
}
.btn-upload-wrapper {
  @apply relative overflow-hidden inline-block;
  .btn-file {
    @apply border border-cblack-default flex items-center gap-2;
  }
  .file {
    @apply absolute inset-0 opacity-0 cursor-pointer;
  }
}
.video-trimmer {
  @apply relative w-full my-4;
  .video-container {
    @apply h-60 mx-auto;
    .btn-player-container {
      @apply text-4xl absolute top-1/4 top-1/4 left-0 right-0 text-white;
    }
  }
  .video-player {
    @apply h-60 mx-auto;
  }
  .range-slider {
    @apply bg-cblack-default h-14 relative overflow-hidden;
    .range-slider__thumb {
      @apply w-4 h-9 bg-white rounded;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='%23333' viewBox='0 0 24 24'%3E%3Cpath d='M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z' /%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center;
    }
    .range-slider__range {
      border-radius: 6px;
      background: transparent;
      border: 4px solid #fff;
      box-sizing: border-box;
      box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.75);
    }
  }
}
.video-popup {
  @apply absolute inset-0 bg-slate-500/30 z-50;
  .btn-times {
    @apply absolute bg-white left-4 top-4;
  }
  .content {
    @apply w-full flex h-screen justify-center items-center;
    &.video-player {
      @apply w-full;
    }
  }
}
//Menu Items
.menu-items {
  .link-item {
    @apply border-b border-gray-300 last:border-b-0 flex items-center justify-between;
    .title {
      @apply flex items-center;
      p {
        @apply font-semibold py-2 text-sm;
      }
      span {
        @apply ml-2 text-xs;
      }
    }
  }
}
//Option Selector Popup
.popup {
  .btn-times {
    @apply bg-cblue-500 flex items-center text-white h-11 w-11 text-center text-xl rounded-full;
  }
  //Option Selector
  &.option-selector {
    .btn-times {
      @apply relative mb-2;
    }
    @apply bg-white;
    .content-container {
      @apply block;
      .search-bar {
        @apply relative mt-4;
        .form-control {
          @apply bg-cwhite-500 py-3 pl-11 w-full mb-3 rounded;
        }
        .icon-container {
          @apply absolute inset-0 pl-3 h-full flex items-center w-10;
          .icon {
            @apply text-xl text-cwhite-400 -mt-4;
          }
        }
      }
    }
  }
  //Image Cropper
  &.popup-img-crop {
    @apply bg-white z-40 py-0;
    .popup-header {
      @apply bg-cblue-400 p-4 rounded-b-lg;
      .btn {
        @apply -mb-8 ml-0 text-center flex items-center;
        .icon {
          @apply mx-auto;
        }
      }
    }
    .img-cropper {
      @apply mt-6;
    }
    .heading {
      @apply text-2xl font-bold mb-4;
    }
    .cropper {
      @apply text-center;
    }
  }
}
.list-selector {
  @apply bg-cwhite-500 p-2 rounded;
  .list-item {
    @apply text-base border-b-2 py-2 px-2;
    &:last-child {
      @apply border-b-0;
    }
    .icon {
      @apply text-2xl text-cwhite-400;
      &.active {
        @apply text-cblue-500;
      }
    }
  }
}
.input-container {
  @apply mb-2;
  .error {
    @apply text-red-500;
  }
  .inline-radio {
    @apply flex ml-4;
    label {
      // @apply mr-2;
    }
    input[type='radio'] {
      @apply h-2 w-2;
    }
  }
}
.toggle-card {
  .toggle-btn {
    &.active {
      @apply bg-black text-white;
    }
  }
  &.active {
    @apply bg-white border-2 border-cgold-500;
    .number {
      @apply bg-cgold-500 text-white;
    }
    .text {
      @apply text-black;
    }
    .toggle-btn {
      @apply border-black;
    }
  }
}
.img-slide {
  .img-desktop {
    @apply hidden md:inline-block w-full h-fit md:max-h-[600px] -mt-10 md:mt-0 md:object-contain;
  }
  .img-mobile {
    @apply inline-block md:hidden lg:hidden w-full inline-block  object-contain #{!important};
  }
}

.img-hover-card {
  &:hover {
    @apply border-transparent md:border-black;
    .overlay-content {
      background-color: rgba(255, 255, 255, 0.8);
      @apply block absolute inset-0 px-2 py-1;
    }
  }
  .overlay-content {
    @apply hidden;
  }
}
.filter {
  &__control {
    z-index: 1;
    @apply bg-cblue-100 border-cblue-100 border-0 h-auto min-h-0 #{!important};
  }

  &__placeholder,
  &__single-value {
    overflow: hidden !important;
    width: 87% !important;
    margin-left: 2rem !important;
    text-overflow: ellipsis;
    white-space: nowrap;

    @apply text-white absolute z-10 pointer-events-none md:w-4/6 #{!important};
  }

  &__value,
  &__single-value {
    @apply text-white pointer-events-none #{!important};
  }
  &__value-container {
    @apply flex p-0 #{!important};
  }
  &__indicator,
  &__dropdown-indicator {
    @apply text-white cursor-pointer #{!important};
  }
  &__menu {
    background: #f4f4f4 !important;
    border-radius: 5px !important;
    z-index: 2 !important;
    width: 320px !important;
    min-width: 100%;
    max-width: 79.9vw;
    border-radius: 2px !important;
    border: none !important;
    box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.2) !important;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__option {
    font-style: normal;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 23px !important;
    color: #191919 !important;
    position: relative;
    cursor: pointer !important;
    padding-right: 30px !important;

    &::before {
      content: '';
      width: 92%;
      height: 100%;
      background-color: #d2d2d2;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 5%;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 19px;
      height: 19px;
      background-color: #c7c2c2;
      border-radius: 50%;
    }

    &:last-child {
      &::before {
        display: none;
      }
    }

    &--is-focused {
      background-color: transparent !important;
    }

    &--is-selected {
      background-color: transparent !important;

      &::after {
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url("data:image/svg+xml,%3Csvg width='19' height='19' viewBox='0 0 19 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle cx='9.5' cy='9.5' r='9.5' fill='%23163F9B'/%3E%3Cpath d='M4.43335 9.49992L8.23335 13.2999L15.2 6.33325' stroke='white' stroke-width='2'/%3E%3C/svg%3E");
      }
    }
  }

  &__group {
    padding: 0;
  }

  &__menu-portal {
    border: 1px solid darkblue;
  }
}
.filter_input {
  &::placeholder {
    @apply text-white absolute pointer-events-none md:w-4/6;
  }
}
.champions-video-carousel {
  .slider-control-bottomcenter {
    @apply -mb-8 #{!important};
  }
}
.champions-video-popup {
  .overlay {
    @apply hidden;
  }
  .paging-item {
    button {
      @apply w-6 h-1 rounded bg-cblue-500 #{!important};
      .paging-dot {
        @apply hidden;
      }
    }
  }
}
/**
* ===============================
* 🚀 Pages CSS
* ===============================
**/

//Pg Question
section {
  &.pg-question {
    @apply p-10;
    .heading {
      @apply text-2xl font-bold mb-4;
    }
    .hr {
      @apply my-2 bg-cblack-default text-cblack-default;
    }
    .sub-heading {
      @apply text-xl font-bold;
    }
    .p {
      @apply text-cblack-default;
    }
    .btn {
      @apply mx-auto my-14;
    }
    .badges {
      @apply p-0 my-2;
      .badge {
        @apply inline-block border border-cblack-default px-2 rounded-lg mr-2 mb-2;
      }
    }
  }
}
//Pg Profile Image
section {
  &.pg-profile-image {
    @apply p-10;
    .heading {
      @apply text-2xl font-bold mb-4;
    }
    .hr {
      @apply my-2 bg-cblack-default text-cblack-default;
    }
    .sub-heading {
      @apply text-xl font-bold;
    }
    .p {
      @apply text-cblack-default;
    }
    .badges {
      @apply p-0 my-2;
      .badge {
        @apply inline-block border border-cblack-default px-2 rounded-lg mr-2 mb-2;
      }
    }
  }
}
//Pg Profile Image
section {
  &.pg-strengths {
    .list {
      li {
        &:last-child {
          @apply border-b-0;
        }
      }
    }
  }
}

//Video Recorder
section {
  &.video-recorder {
    @apply h-screen w-full bg-slate-800 overflow-hidden;
    // @apply bg-cblack-default;
    .stream {
      @apply w-full;
    }
  }
}

//Header
section {
  &.header {
    @apply bg-cblue-500 mx-2 p-4 rounded-b-lg;
    &.header-fixed {
      .btn-menu {
        @apply -mb-8;
      }
    }
    .btn-times,
    .btn-menu {
      @apply bg-cblue-400 text-white p-0;
      .icon {
        @apply m-auto;
      }
    }
    .heading {
      @apply text-2xl text-white font-bold;
    }
  }
}

header {
  &.header-section {
    .logo {
      @apply w-24 md:w-60;
    }
    .outer-container {
      @apply w-full py-10 mx-1 lg:mx-1  flex flex-row lg:flex-col items-center;
    }
  }
  &.is-sticky {
    @apply relative md:sticky md:top-0 z-10;
    animation: slide-down 0.7s;
    .hide-on-sticky {
      @apply hidden;
    }
    .outer-container {
      @apply py-2;
    }
    .show-on-sticky {
      @apply block;
    }
    .menu-icon-container {
      @apply w-20;
    }
    .menu-container {
      @apply w-full;
    }
    .container {
      @apply md:flex-row;
    }
    .logo {
      @apply w-32;
    }
    @keyframes slide-down {
      0% {
        opacity: 0;
        transform: translateY(-100%);
      }
      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  .show-on-sticky {
    @apply hidden;
  }
  &.header-landing-page {
    .outer-container {
      @apply py-2;
    }
    .menu-icon-container {
      @apply w-20;
    }
    .menu-container {
      @apply w-full;
    }
    .container {
      @apply md:flex-row;
    }
    .logo {
      @apply w-44;
    }
  }
}

@media only screen and (min-width: 768px) {
  header {
    &.is-sticky {
      .md-hide-on-sticky {
        @apply hidden;
      }
      .md-show-on-sticky {
        @apply block;
      }
    }
    .md-show-on-sticky {
      @apply hidden;
    }
  }
}

//Menu Popup
.menu-popup {
  @apply fixed top-0 left-0 w-full h-screen z-50 bg-zinc-100;
  &.hidden {
    display: none;
  }
  .popup-bar {
    @apply bg-cgold-600 flex items-center justify-end px-4 py-3;
  }
}

//Video Trimmer
section {
  &.pg-video-trimmer {
    @apply flex flex-col justify-center items-center h-screen text-center px-8;
    .heading {
      @apply text-2xl font-bold mt-2;
    }
  }
}

//Profile Data
section {
  &.pg-profile-data {
    @apply p-8;
    .content {
      @apply border-b-2 border-gray-600 pb-3 mb-5;
      .heading {
        @apply text-2xl font-bold;
      }
      .description {
        @apply text-base;
      }
    }
    //Form Styling
    input {
      @apply w-full border rounded bg-zinc-200 p-2;
      &.small-one {
        @apply w-full mb-0 mr-2;
      }
    }
    textarea {
      @apply w-full h-auto border rounded bg-zinc-200 mb-4 p-2;
    }
    input[type='radio'] {
      @apply appearance-none h-6 w-6 rounded bg-zinc-200 mr-2;
      &.small-one {
        @apply h-4 w-4 mr-1;
      }
    }
    input[type='radio']:checked {
      @apply bg-red-500;
      box-shadow: 0px 0px 0px 3px white inset;
    }
    label {
      @apply text-sm;
    }
  }
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

/**
* 🚀 Animations
**/
.slide-menu-container {
  width: 100%;
  height: 100vh;
  left: -100%;
  top: 0;
  transform: translate(0%);
  transition: transform 400ms ease-in-out;

  &.open {
    transform: translateX(100%);
    z-index: 100;
  }
}

.slider-control-bottomcenter {
  position: relative;
  top: 50px;
}

.otp-code-box {
  @apply flex justify-center mx-4 my-0;
}
.otp-focus-style {
  @apply border-2 border-cwhite-400 shadow;
}
.otp-input-style {
  @apply w-12 h-12 text-4xl md:w-16 md:h-16 text-4xl my-0 mx-1 md:mx-2 rounded border-2 border-cwhite-400 shadow #{!important};
}

.slide {
  display: flex;
  justify-content: center;
}

.carousel .control-dots {
  bottom: -17px;
}

.carousel .control-dots .dot {
  background-color: #110f0f;
}

#quels-slider {
  .carousel .control-dots .dot {
    background-color: #fff;
  }
}

.cropper-modal {
  opacity: 0 !important;
}

.cropper-crop-box,
.cropper-view-box {
  border-radius: 50%;
}

.cropper-view-box {
  box-shadow: 0 0 0 1px #39f;
  outline: 0;
}

.project-student-carousel {
  .slider-control-bottomcenter {
    position: relative;
    top: 20px;
  }
}

.svg-button {
  svg {
    width: 100%;
    height: 100%;
  }
}

.cutom-checkbox input[type='checkbox'] {
  width: 25px;
  height: 25px;
  position: relative;
  border: 2px solid lightgray;
  border-radius: 2px;
  background: lightgray;
  cursor: pointer;
  line-height: 0;
  margin: 0 0.6em 0 0;
  outline: 0;
  padding: 0 !important;
  vertical-align: text-top;
  height: 20px;
  width: 20px;
  -webkit-appearance: none;
  opacity: 0.5;
  border-radius: 100%;
}

.cutom-checkbox input[type='checkbox'] {
  opacity: 1;
}
.cutom-checkbox input[type='checkbox']:checked {
  background: #ed4d4d !important;
  border-color: #ed4d4d;
}
.cutom-checkbox input[type='checkbox']:checked:before {
  border-color: white !important;
}
.cutom-checkbox input[type='checkbox']:hover {
  background-color: lightgray;
  opacity: 1;
}
.cutom-checkbox input[type='checkbox']:before {
  content: '';
  position: absolute;
  right: 50%;
  top: 50%;
  width: 4px;
  height: 10px;
  border: solid lightgray;
  border-width: 0 2px 2px 0;
  margin: -1px -1px 0 -1px;
  transform: rotate(45deg) translate(-50%, -50%);
  z-index: 2;
}
