.a-button,
.input-btn-check input + label,
.input-btn-check input + span {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  cursor: pointer;
  font-size: 1rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 18px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  overflow: hidden;
  text-align: center;
  text-decoration: none !important;
  vertical-align: middle;
  z-index: 1;
  color: red;
  border: 2px solid #000;
  border-radius: 30px;
  background: 0 0;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.header:before {
  content: '';
  position: absolute;
  z-index: 6;
  left: 0;
  top: 2rem;
  width: 0.5rem;
  height: 8rem;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(33%, #00f),
    color-stop(33%, #fff),
    color-stop(66%, #fff),
    color-stop(66%, red)
  );
  background: linear-gradient(180deg, #00f 33%, #fff 33%, #fff 66%, red 66%);
}

.menu-toolbar .as--close {
  display: none;
}

.menu-is-open .menu-toolbar .btn-menu .a-icon {
  display: none;
}

.menu-is-open .menu-toolbar .btn-menu .a-icon.as--close {
  display: block;
}

.search-is-open .menu-toolbar .btn-search .a-icon {
  display: none;
}

.search-is-open .menu-toolbar .btn-search .a-icon.as--close {
  display: block;
}

.share-is-open .menu-toolbar .btn-share .a-icon {
  display: none;
}

.share-is-open .menu-toolbar .btn-share .a-icon.as--close {
  display: block;
}

.header-quicklinks {
  position: absolute;
  top: 2.5rem;
  right: 4rem;
}

.header-quicklinks li {
  display: inline-block;
  margin-left: 2.5rem;
}

.header-quicklinks li a {
  color: #fff;
  text-decoration: none;
  font-weight: 400;
  font-size: 1.2rem;
}

.header-quicklinks li a.a-button {
  border-width: 0.1rem;
  font-size: 1.1rem;
}

.default-banner-inner .logo {
  position: absolute;
  top: 3.2rem;
  left: 16rem;
}

@media only screen and (max-width: 56em) {
  .default-banner-inner .logo {
    display: none;
  }
}

@media only screen and (max-width: 88em) {
  .header-quicklinks {
    top: 0.9375rem;
    right: 0.9375rem;
  }

  .header-quicklinks li {
    margin-left: 0.625rem;
  }

  .header-quicklinks li a,
  .header-quicklinks li a.a-button {
    font-size: 0.95rem;
  }
}

@media only screen and (max-width: 56em) {
  .header-quicklinks {
    display: none;
  }
}

.default-banner {
  background: #062870;
  color: #fff;
}

.default-banner.as--bottom-content .default-banner-inner {
  padding-bottom: 15rem !important;
}

.default-banner.as--bottom-content .default-banner-inner-new {
  padding-bottom: 25rem !important;
}

@media only screen and (max-width: 56em) {
  .default-banner {
    background: #062870;
  }
}

.default-banner-inner {
  text-align: center;
  position: relative;
  padding: 0.1rem 0 0.1rem 12rem;
  min-height: 15rem;
}

@media only screen and (max-width: 56em) {
  .default-banner-inner .logo {
    display: none;
  }
}

.default-banner-inner .default-banner-title {
  margin-top: 12.2rem;
  padding-bottom: 2rem;
}

.default-banner-inner .default-banner-title .a-title-1 {
  margin: 1rem 0;
}

@media screen and (min-width: 56.0625em) {
  .default-banner-inner .default-banner-title .a-title-1 {
    width: 55%;
    margin: auto;
  }
}

@media screen and (min-width: 88.0625em) {
  .default-banner-inner .default-banner-title .a-title-1 {
    width: 63%;
    margin: auto;
  }
}

@media only screen and (max-width: 56em) {
  .default-banner-inner {
    padding: 0.1rem 0;
    min-height: 0;
  }

  .default-banner-inner .default-banner-title {
    margin-top: 3.5rem;
  }

  .default-banner-inner .default-banner-title .a-title-1 {
    font-size: 27px;
  }
}

@media only screen and (max-width: 56em) {
  .header:before {
    position: fixed;
    top: 0;
    height: 0.5rem;
    width: 100%;
    background: -webkit-gradient(
      linear,
      left top,
      right top,
      color-stop(33%, #00f),
      color-stop(33%, #fff),
      color-stop(66%, #fff),
      color-stop(66%, red)
    );
    background: linear-gradient(90deg, #00f 33%, #fff 33%, #fff 66%, red 66%);
  }
}

.dore {
  border: #d4be6f solid 1px;
  color: #3971e3;
  font-weight: 900;
}

.dore:hover {
  border: 1px solid #d4be6f;
  background-color: #d4be6f;
  color: #fff;
}

.dore-white {
  border: #d4be6f solid 2px;
  color: #fff;
  font-weight: 900;
}

.dore-white:hover {
  background-color: #d4be6f;
  color: #fff;
}

.button-simple {
  border: #e2e2e2 solid 1px;
  color: #3971e3;
  font-weight: 500;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-top: auto;
  margin-bottom: 19px;
}

.button-simple:hover {
  border: 1px solid #d4be6f;
  background-color: #d4be6f;
  color: #fff;
}

.button-simple:hover svg {
  fill: #fff !important;
}

.button-variant {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  text-decoration: none;
  color: #c1a74a;
  font-size: 20px;
  font-weight: 500;
}

.button-variant svg {
  margin: auto;
  margin-bottom: 1rem;
}

.button-bg-white {
  background-color: #fff;
  color: #3971e3;
  font-weight: 900;
  border: none;
}

.button-bg-white:hover {
  background-color: #d4be6f;
  color: #fff;
}

.button-footer {
  background-color: #fff;
  border: #e2e2e2 solid 1px;
  color: #000;
}

.menu-wrap {
  width: 11.9rem;
  height: 100%;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  color: #535353;
}

.menu-wrap:before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 100%;
  height: 100%;
  width: 100vw;
  background: rgba(0, 0, 0, 0.4);
  pointer-events: none;
  opacity: 0;
  -webkit-transition: 0.5s cubic-bezier(0.32, 0.95, 0, 0.96);
  transition: 0.5s cubic-bezier(0.32, 0.95, 0, 0.96);
}

.menu-wrap.menu-is-open:before,
.menu-wrap.search-is-open:before,
.menu-wrap.share-is-open:before {
  opacity: 1;
  -webkit-transition: 0.5s cubic-bezier(0.32, 0.95, 0, 0.96) 0.1s;
  transition: 0.5s cubic-bezier(0.32, 0.95, 0, 0.96) 0.1s;
}

@media only screen and (max-width: 56em) {
  .menu-wrap.menu-is-open,
  .menu-wrap.search-is-open,
  .menu-wrap.share-is-open {
    background: #0b3ca1 !important;
  }
}

@media only screen and (max-width: 75em) {
  .menu-wrap {
    width: 7.5rem;
  }
}

@media only screen and (max-width: 56em) {
  .menu-wrap {
    width: 100%;
    height: 5.3125rem;
    background: #062870;
  }

  .menu-wrap:before {
    content: '';
    position: absolute;
    top: 1rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 4.375rem;
    height: 4.375rem;
    opacity: 1;
    background: url(https://worldskills-france.org/app/themes/worldskill/assets/img/common/logo/logo_text_big.png)
      no-repeat center center;
    background-size: 100% auto;
  }

  .menu-wrap:after {
    display: none;
  }
}

.menu-wrap:after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff
    url(https://worldskills-france.org/app/themes/worldskill/assets/img/common/logo/logo.png)
    no-repeat center 70%;
  border-right: 0.3rem solid #efefef;
  border-left: 0.65rem solid #efefef;
}

.menu-toolbar {
  width: 100%;
  text-align: center;
  padding-top: 3rem;
}

.menu-toolbar > li {
  min-height: 2.8rem;
}

.menu-toolbar button {
  background: 0;
  border: 0;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 56em) {
  .menu-toolbar > li {
    min-height: 0;
  }

  .menu-toolbar .a-icon {
    fill: #fff;
    stroke: transparent;
    -webkit-transition: 0.35s;
    transition: 0.35s;
  }

  .menu-toolbar .btn-menu {
    position: absolute;
    top: 1.875rem;
    left: 1.25rem;
  }

  .menu-toolbar .btn-search {
    position: absolute;
    top: 1.875rem;
    right: 1.25rem;
  }

  .menu-toolbar .btn-share {
    position: fixed;
    z-index: 6;
    bottom: 1.5rem;
    right: 1.5rem;
    background: #0b3ca1;
    padding: 0.75rem;
    border-radius: 50%;
  }

  .menu-toolbar .btn-share .a-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
}

.a-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  fill: currentColor;
  stroke: transparent;
}

.a-icon.as--25 {
  width: 1.5rem;
  height: 1.5rem;
}

svg:not(:root) {
  overflow: hidden;
}

.as--link {
  fill: #3971e3;
  stroke: transparent;
}

.menu-toolbar .as--close {
  display: none;
}

.main-menu {
  background: #fff;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 11.9rem;
  padding: 2.5rem 2.5rem 0;
  width: 25rem;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  -webkit-transform: translateX(6rem);
  transform: translateX(6rem);
  -webkit-transition: 0.5s cubic-bezier(0.32, 0.95, 0, 0.96);
  transition: 0.5s cubic-bezier(0.32, 0.95, 0, 0.96);
}

.menu-is-open .main-menu {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.main-menu > ul {
  overflow: auto;
  max-height: calc(100% - 5rem);
  padding: 0.1rem 0;
}

.main-menu > ul .btn-submenu .a-icon,
.main-menu > ul a .a-icon {
  margin-left: auto;
}

.main-menu > ul .a-icon {
  fill: #bebebe;
  stroke: transparent;
}

.main-menu .social {
  text-align: center;
  position: absolute;
  bottom: 3.125rem;
  left: 0;
  width: 100%;
}

.main-menu .social a {
  text-decoration: none;
  margin: 0 0.375rem;
  color: #0b3ca1;
}

.main-menu .social svg {
  color: #3971e3;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.main-menu .social svg:hover {
  color: #d4be6f;
}

@media only screen and (max-width: 56em) {
  .main-menu {
    z-index: 6;
    top: 5.3125rem;
    left: 0;
    padding: 1.875rem 1.25rem 0;
    width: 100%;
    height: calc(100vh - 5.3125rem);
  }
}

.main-menu > ul .btn-submenu,
.main-menu > ul a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 400;
  color: #535353;
  text-decoration: none;
  margin: 0.0625rem 0;
  text-align: left;
}

.main-menu li:not(.small-link) + .small-link {
  margin-top: 1.875rem;
}

.main-menu .small-link a {
  font-weight: 300;
  font-size: 1.125rem;
}

@media only screen and (max-width: 56em) {
  .submenu {
    padding: 7rem 2rem 0;
    left: 0;
    width: 100%;
  }
}

.submenu-is-open > .submenu {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.submenu {
  background: #fff;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 100%;
  padding: 2.5rem 2.5rem 0;
  width: 25rem;
  height: 100%;
  border-left: 0.1rem solid #efefef;
  pointer-events: none;
  opacity: 0;
  -webkit-transform: translateX(6rem);
  transform: translateX(6rem);
  -webkit-transition: 0.5s cubic-bezier(0.32, 0.95, 0, 0.96);
  transition: 0.5s cubic-bezier(0.32, 0.95, 0, 0.96);
  text-align: center;
}

.submenu .btn-submenu,
.submenu a {
  font-size: 1.125rem !important;
}

@media only screen and (max-width: 56em) {
  .submenu {
    padding: 4.375rem 1.25rem 0;
    left: 0;
    width: 100%;
  }
}

.submenu-is-open > .btn-submenu {
  color: #3971e3 !important;
}

.submenu-is-open > .btn-submenu .a-icon {
  fill: #3971e3;
  stroke: transparent;
}

.submenu-is-open > .submenu {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.submenu-back {
  position: absolute;
  top: 1.875rem;
  left: 1.25rem;
  display: none;
}

.submenu-back .submenu-back-btn {
  background: 0;
  border: 0;
  padding: 0;
  margin: 0;
  color: #3971e3;
  font-size: 1.125rem;
  font-weight: 400;
}

.submenu-back .submenu-back-btn .a-icon {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  margin-right: 0.7rem;
}

@media only screen and (max-width: 56em) {
  .submenu-back {
    display: block;
  }
}

.main-menu > ul .a-icon {
  fill: #bebebe;
  stroke: transparent;
}

.main-menu > ul .btn-submenu:hover,
.main-menu > ul a:hover {
  color: #3971e3;
}

.submenu .btn-submenu,
.submenu a {
  font-size: 1.25rem !important;
}

/* FOOTER */

.footer {
  text-align: center;
  background: #f9f9f9;
  position: relative;
  z-index: 1;
}

.footer-inner {
  padding: 4rem 0 2rem 13rem;
}

.footer-inner img {
  width: 150px;
  display: inherit;
}

.footer-inner .footer-btn {
  margin: 2.8rem 0;
}

.h-margin-top-2 {
  margin-top: 2rem !important;
}

.footer-text {
  font-size: 0.9rem;
  color: #535353;
  font-weight: 300;
}

.footer-inner .footer-btn li {
  display: inline-block;
  margin: 0 0.7rem;
}

.footer-inner .social {
  margin: 2.5rem 0 1.5rem;
}

.footer-inner .social a {
  text-decoration: none;
  margin: 0 1.3rem;
}

.footer-inner .social svg {
  color: #3971e3;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.footer-inner .social svg:hover {
  color: #d4be6f;
}

.footer-inner .footer-nl {
  display: inline-block;
  width: 100%;
  max-width: 52rem;
  position: relative;
  height: 3.8rem;
}

.footer-inner .footer-nl * {
  pointer-events: none;
}

.footer-inner .footer-nl pre {
  padding: 0;
  height: 100%;
}

.footer-inner .footer-nl pre div.gform_wrapper {
  height: 100%;
}

.footer-inner .footer-nl .ginput_container_email {
  max-width: 52rem;
  width: 100%;
}

.footer-inner .footer-nl input {
  pointer-events: auto;
}

.footer-inner .footer-nl [type='email'],
.footer-inner .footer-nl [type='text'] {
  width: 100%;
  max-width: 52rem;
  border: 0.1rem solid #e2e2e2;
  border-radius: 3rem;
  font-size: 1.6rem;
  padding-right: 10rem;
  padding-left: 2rem;
  height: 3.8rem;
  position: absolute;
  left: 0;
  bottom: 0;
}

@media screen and (max-width: 38em) {
  .footer-inner .footer-nl [type='email'],
  .footer-inner .footer-nl [type='text'] {
    width: 95% !important;
  }
}

.footer-inner .footer-nl [type='email']:focus,
.footer-inner .footer-nl [type='text']:focus {
  outline: 0;
}

.footer-inner .footer-nl [type='submit'] {
  position: absolute;
  font-size: 1.6rem;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 10px 18px;
  margin: 0;
  overflow: hidden;
  text-align: center;
  text-decoration: none !important;
  vertical-align: middle;
  z-index: 1;
  border-radius: 30px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border: #d4be6f solid 1px;
  color: #3971e3;
  font-weight: 900;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 90%;
  background-color: #fff;
  height: 38px;
  line-height: 16px !important;
}

@media screen and (max-width: 32em) {
  .footer-inner .footer-nl [type='submit'] {
    left: 75%;
  }
}

.footer-inner .footer-links {
  margin-top: 1rem;
}

.footer-inner .footer-links li {
  display: inline-block;
  margin: 0 0.7rem;
  font-weight: 400;
  font-size: 1rem;
  color: #535353;
}

.footer-inner .footer-links li a {
  text-decoration: none;
}

.footer-inner .footer-links li a:hover {
  color: #3971e3;
}

.footer-inner .footer-hosting {
  color: green;
  margin-top: 1.1rem;
  font-weight: 400;
  font-size: 1rem;
}

.footer-inner .footer-hosting svg {
  margin-bottom: 4px;
}

@media only screen and (max-width: 56em) {
  .footer-inner {
    padding: 5rem 1.5rem 8rem;
  }

  .footer-inner .footer-btn {
    margin: 4rem 0;
  }

  .footer-inner .footer-btn li {
    display: inline-block;
    margin: 0.7rem 0.5rem;
  }

  .footer-inner .social {
    margin: 3rem 0;
  }

  .footer-inner .social a {
    display: inline-block;
    margin: 1rem 0.8rem;
  }
}

#btn-goto-top {
  position: absolute;
  top: 8%;
  right: 7%;
}

#btn-goto-top svg {
  cursor: pointer;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  border: 1px solid #e2e2e2;
  padding: 1.8rem;
  border-radius: 50%;
  color: #3971e3;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

#btn-goto-top svg:hover {
  background-color: #3971e3;
  color: #fff;
}

.button-footer {
  background-color: #fff;
  border: #e2e2e2 solid 1px;
  color: #000;
  font-weight: 600;
}

.a-icon.as--50 {
  width: 3rem;
  height: 3rem;
}

.a-icon.as--35 {
  width: 2.1rem;
  height: 2.1rem;
}

.a-icon.as--20 {
  width: 1.2rem;
  height: 1.2rem;
}

.h-fz-24 {
  font-size: 1.5rem !important;
  font-weight: 300;
}

/* SEARCH */

.search-wrap {
  background: #fff;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 11.875rem;
  padding: 2.5rem 2.5rem 0;
  width: 50rem;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  -webkit-transform: translateX(3.75rem);
  transform: translateX(3.75rem);
  -webkit-transition: 0.5s cubic-bezier(0.32, 0.95, 0, 0.96);
  transition: 0.5s cubic-bezier(0.32, 0.95, 0, 0.96);
  text-align: left;
}

.search-is-open .search-wrap {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.search-wrap .search-bloc {
  margin: 1.25rem 0 3.125rem;
  position: relative;
}

.search-wrap .search-bloc [type='text'] {
  width: 100%;
  border: 0.0625rem solid #e2e2e2;
  border-radius: 1.875rem;
  font-size: 1rem;
  padding-right: 6.25rem;
  padding-left: 1.25rem;
  height: 2.375rem;
}

.search-wrap .search-bloc [type='submit'] {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 600;
}

.search-wrap .search-quicklinks {
  margin-top: 1.25rem;
}

.search-wrap .search-quicklinks .a-button {
  margin: 0 0.625rem 0.625rem 0;
}

@media only screen and (max-width: 56em) {
  .search-wrap {
    top: 5.3125rem;
    left: 0;
    padding: 2.5rem 1.25rem 0;
    width: 100%;
    height: calc(100vh - 5.3125rem);
  }
}

/* SHARE */
.share-wrap {
  background: #fff;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 11.875rem;
  padding: 2.5rem 2.5rem 0;
  width: 25rem;
  height: 100%;
  pointer-events: none;
  opacity: 0;
  -webkit-transform: translateX(3.75rem);
  transform: translateX(3.75rem);
  -webkit-transition: 0.5s cubic-bezier(0.32, 0.95, 0, 0.96);
  transition: 0.5s cubic-bezier(0.32, 0.95, 0, 0.96);
  text-align: left;
}

.share-is-open .share-wrap {
  pointer-events: auto;
  opacity: 1;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.share-wrap a {
  display: block;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: 400;
  margin: 0.3rem 0;
  color: #535353;
}

.share-wrap a:hover {
  color: #3971e3;
}

@media only screen and (max-width: 56em) {
  .share-wrap {
    top: 5.3125rem;
    left: 0;
    padding: 2.5rem 1.25rem 0;
    width: 100%;
    height: calc(100vh - 5.3125rem);
  }
}

/* MISSION TEAM STYLES */
@media only screen and (min-width: 886px) {
  body.wsf-sidebar-page section#candidate-profile,
  body.wsf-sidebar-page section#learn-from-champions,
  body.wsf-sidebar-page section#champion-search,
  body.wsf-sidebar-page section#champion-video,
  body.wsf-sidebar-page section#forces,
  body.wsf-sidebar-page section#roles,
  body.wsf-sidebar-page section#devine,
  body.wsf-sidebar-page section#medals,
  body.wsf-sidebar-page section#videos,
  body.wsf-sidebar-page div#entreprise,
  body.wsf-sidebar-page section#diplomas,
  body.wsf-sidebar-page section#fiche,
  body.wsf-sidebar-page section#decouveete,
  body.wsf-sidebar-page section#partners {
    margin-left: 11.9rem;
    margin-right: 0;
  }
}
